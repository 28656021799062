import React from "react";
import {expertiseData} from "../Data/data"
import CountUp from 'react-countup';
import { Link } from "react-router-dom";

export default function AboutUs(){

    return(
        <section className="container relative md:py-24 py-16" id="about">
            <div className="lg:col-span-7">
                <div className="container md:mt-24 mt-16">

                    <div className="lg:ms-5">
                        <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">About me</h3>

                        <p className="text-slate-400 max-w-xl text-[15px]">As a developer and IT consultant, I leverage my technical expertise to design, build, and manage complex digital solutions. My role involves crafting seamless user experiences, optimizing backend operations, integrating APIs, and orchestrating Docker containers.</p>
                        <p className="text-slate-400 max-w-xl text-[15px] mt-6">I collaborate closely with clients to understand their needs, ensuring successful project outcomes and driving business growth.</p>

                        <div className="mt-6">
                            <Link to="#project" className="btn bg-amber-500/10 hover:bg-amber-500 border-amber-500/10 hover:border-amber-500 text-amber-500 hover:text-white rounded-md me-2 mt-2">See Work</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="container md:mt-24 mt-16">
                <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    {expertiseData.map((item,index)=>{
                        const Icon = item.Icon
                        return(
                            <div className="flex group shadow shadow-gray-200 dark:shadow-gray-800 dark:hover:shadow-gray-700 items-center p-3 rounded-lg bg-white dark:bg-slate-900" key={index}>
                            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-amber-500/10 group-hover:bg-amber-500 text-amber-500 group-hover:text-white text-center rounded-xl me-5 transition-all duration-500">
                                <div className="rotate-45">
                                <Icon className="h-5 w-5"/>
                                </div>
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-[17px] font-medium">{item.title}</h4>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div> */}
        </section>
    )
}