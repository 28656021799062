import React from "react";

export default function Projects(){
    return(
        <>
        <section className="relative md:py-24 py-16" id="project">
            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">Technologies and Services</h3>

                    <p className="text-slate-400 max-w-xl mx-auto text-[15px]">Throughout my career, I have not only leveraged a wide range of services but have also expertly implemented them, culminating in a comprehensive portfolio of successful projects and satisfied clients.</p>
                </div>

                <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                    <div>
                        <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                            <img src="/images/works/1.png" alt=""/>
                            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                        </div>
                        <p>Ruby on Rails</p>
                    </div>
                    <div>
                        <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                            <img src="/images/works/2.png" alt=""/>
                            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                        </div>
                        <p>Docker</p>
                    </div>
                    <div>
                        <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                            <img src="/images/works/3.png" alt=""/>
                            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                        </div>
                        <p>MySQL</p>
                    </div>
                    <div>
                        <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                            <img src="/images/works/4.png" alt=""/>
                            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                        </div>
                        <p>Tailwind</p>
                    </div>
                    <div>
                        <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                            <img src="/images/works/5.png" alt=""/>
                            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                        </div>
                        <p>Coinbase</p>
                        <small>Custom Integration</small>
                    </div>
                    <div>
                        <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                            <img src="/images/works/6.png" alt=""/>
                            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                        </div>
                        <p>Meta Conversions API</p>
                        <small>Custom Integration</small>
                    </div>
                    <div>
                        <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                            <img src="/images/works/7.png" alt=""/>
                            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                        </div>
                        <p>Skroutz Markeplace</p>
                        <small>Custom Integration</small>
                    </div>
                    <div>
                        <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                            <img src="/images/works/8.png" alt=""/>
                            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                        </div>
                        <p>Simpler</p>
                        <small>Custom Integration</small>
                </div></div>
            </div>
        </section>
        </>
    )
}