import React from "react";
export default function Experience(){
    return(
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="experience">
            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">Work Experience</h3>
                </div>

                <div className="grid grid-cols-1 mt-8">
                    <div className="relative after:content-[''] after:absolute after:top-0 ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
                        
                        <div className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <img src="/images/logos/generation-y-persona.png" className="rounded-full h-9 w-9 md:ms-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">Generation-Y</h5>
                                    <h6 className="text-sm mb-0">Sep 2021-Present</h6>
                                    <h6 className="text-sm mb-0">Athens, GR</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <h5 className="title mb-1 font-semibold">Ruby on Rails Developer</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">Bringing E-commerce Dreams to Life with Ruby on Rails: Crafting Scalable, Secure, and User-Centric Online Shopping Solutions.</p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="text-start md:ms-8 relative md:order-2">
                                    <img src="/images/logos/self-persona.png" className="rounded-full h-9 w-9 md:me-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">Freelancer</h5>
                                    <h6 className="text-sm mb-0">Feb 2020-Present</h6>
                                    <h6 className="text-sm mb-0">Athens, GR</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                    <h5 className="title mb-1 font-semibold">Web Developer & Consultant</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">Elevating Business Through Strategic IT Consulting: Transforming Challenges into Opportunities.</p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <img src="/images/logos/cloudboxes-logo.png" className="rounded-full h-9 w-9 md:ms-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">Cloudboxes.io</h5>
                                    <h6 className="text-sm mb-0">2019-22</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <h5 className="title mb-1 font-semibold">Web Developer</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">Engaged in full-stack development, encompassing server management, backend and frontend development, API integration, and Docker containerization.</p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="text-start md:ms-8 relative md:order-2">
                                    <img src="/images/logos/novaglass.png" className="rounded-full h-9 w-9 md:me-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">NovaGlass</h5>
                                    <h6 className="text-sm mb-0">2015-2021</h6>
                                    <h6 className="text-sm mb-0">Athens, GR</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                    <h5 className="title mb-1 font-semibold">Eshop operator, Project Manager & Consulting</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">Responsible for managing and optimizing the e-shop operations, as well as overseeing project management initiatives.</p>
                                </div>
                            </div>
                        </div> 

                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <img src="/images/logos/txm.png" className="rounded-full h-9 w-9 md:ms-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">theXtreme.me</h5>
                                    <h6 className="text-sm mb-0">March-September 2017</h6>
                                    <h6 className="text-sm mb-0">Athens, GR</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <h5 className="title mb-1 font-semibold">Ruby On Rails Developer</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]"><em><strong>Internship</strong></em> - Development of web API endpoints for use on mobile application.</p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="text-start md:ms-8 relative md:order-2">
                                    <img src="/images/logos/wedos.png" className="rounded-full h-9 w-9 md:me-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">Wedos</h5>
                                    <h6 className="text-sm mb-0">Feb-Aug 2016</h6>
                                    <h6 className="text-sm mb-0">Hluboká nad Vltavou, CZ</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                    <h5 className="title mb-1 font-semibold">Suricata and ELK Stack for monitoring real-time network traffic</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]"><em><strong>Erasmus+ Internship</strong></em> - Development of an IPS/IDS solution for filtering network traffic and blocking malicious activity using Suricata and Elastic ELK Stack on Linux servers.</p>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </section>
    )
}